import React from "react"
import { Layout } from "../layouts/Site"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { Page, Section } from "../components/Containers"
import { Link, PageHeading, Paragraph } from "../components/Headings";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

export default function ErrorPage() {
  const { defaultMetadata } = useStaticQuery(query)

  return <Layout title='Page not found'>
    <Helmet>
      <meta name="description" content={defaultMetadata.metaDescription} />
    </Helmet>
    <Page>
      <Section className='text-center'>
        <PageHeading><Icon icon={faSearch} /></PageHeading>
        <Paragraph>
          Sorry, but we could not find what you are looking for...<br />
          <Link href='/contact'>Let us know what we're missing</Link> and we'll get back to you.
        </Paragraph>
        <Paragraph className='mt-8'>
          <Link href='/'>Start browsing our website from the home page.</Link>
        </Paragraph>
      </Section>
    </Page>
  </Layout>
}

const query = graphql`{
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`